import Chip from '@material-ui/core/Chip'
import { ReferenceArrayInput, SingleFieldList, ChipField, required, ReferenceInput, SelectArrayInput, SelectInput, Button, Create, Edit, SimpleForm, List, TextInput, DateInput, ReferenceManyField, Datagrid, TextField, DateField, EditButton } from 'react-admin';

export const TextArrayField = ({ record, source }) => {
    const array = record[source]
    if (typeof array === 'undefined' || array === null || array.length === 0) {
        return <div />
    } else {
        return (
            <>
                {array.map(item => <Chip label={item} key={item} />)}
            </>
        )
    }
}
TextArrayField.defaultProps = { addLabel: true }


export const RefferenceArraySelect = ({ record,source}) => {
    const states = record[source];
    return <ReferenceArrayInput  source="carrier_id" reference={ `marketplace/carriers`} > {
        //${"?states=" + states.join("&states=")}`} > 
    }
        <SelectArrayInput optionText="name"/>
    </ReferenceArrayInput>
}



RefferenceArraySelect.defaultProps = { 
    addLabel: false 
}