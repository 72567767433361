import React from "react";
import { AutocompleteInput, DeleteButton, useReferenceArrayInputContext, ReferenceArrayInput, AutocompleteArrayInput, SingleFieldList, ChipField, required, ReferenceInput, SelectArrayInput, SelectInput, Button, Create, Edit, SimpleForm, List, TextInput, DateInput, ReferenceManyField, Datagrid, TextField, DateField, EditButton } from 'react-admin';
import states from "../states"
import carriers from "../carriers"
import { TextArrayField, RefferenceArraySelect } from "../components/chip"


export const HealthSherpaKeysList = (props) => <List {...props}>
    <Datagrid>
        <TextField source="state" />
        <TextField source="carrier_name" />
        <TextField source="agent_key" />

        <DeleteButton/>
    </Datagrid>
</List>
    ;

export const CreateHealthSherpaKey = (props) => (
    <Create {...props}>
        <SimpleForm>
            <SelectInput label="state" source="state" choices={Object.keys(states).map(k => {
                return { id: k, name: states[k] }
            })} />
            <ReferenceInput filter={{ state: Object.keys(states) }} source="carrier_id" reference="marketplace-carriers" >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <TextInput source="agent_key" />
        </SimpleForm>
    </Create>
);

