export default {
AK: "Alaska (AK)",
AL: "Alabama (AL)",
AR: "Arkansas (AR)",
AZ: "Arizona (AZ)",
CA: "California (CA)",
CO: "Colorado (CO)",
CT: "Connecticut (CT)",
DE: "Delaware (DE)",
FL: "Florida (FL)",
GA: "Georgia (GA)",
HI: "Hawaii (HI)",
IA: "Iowa (IA)",
ID: "Idaho (ID)",
IL: "Illinois (IL)",
IN: "Indiana (IN)",
KS: "Kansas (KS)",
KY: "Kentucky (KY)",
LA: "Louisiana (LA)",
MA: "Massachusetts (MA)",
MD: "Maryland (MD)",
ME: "Maine (ME)",
MI: "Michigan (MI)",
MN: "Minnesota (MN)",
MO: "Missouri (MO)",
MS: "Mississippi (MS)",
MT: "Montana (MT)",
NC: "North Carolina (NC)",
ND: "North Dakota (ND)",
NE: "Nebraska (NE)",
NH: "New Hampshire (NH)",
NJ: "New Jersey (NJ)",
NM: "New Mexico (NM)",
NV: "Nevada (NV)",
NY: "New York (NY)",
OH: "Ohio (OH)",
OK: "Oklahoma (OK)",
OR: "Oregon (OR)",
PA: "Pennsylvania (PA)",
RI: "Rhode Island (RI)",
SC: "South Carolina (SC)",
SD: "South Dakota (SD)",
TN: "Tennessee (TN)",
TX: "Texas (TX)",
UT: "Utah (UT)",
VA: "Virginia (VA)",
VT: "Vermont (VT)",
WA: "Washington (WA)",
WI: "Wisconsin (WI)",
WV: "West Virginia (WV)",
WY: "Wyoming (WY)",
}