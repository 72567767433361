import React, { useState } from "react";
import { useReferenceArrayInputContext , ReferenceArrayInput, AutocompleteArrayInput, SingleFieldList, ChipField, required, ReferenceInput, SelectArrayInput, SelectInput, Button, Create, Edit, SimpleForm, List, TextInput, DateInput, ReferenceManyField, Datagrid, TextField, DateField, EditButton, ArrayInput } from 'react-admin';
import states from "../states"
import carriers from "../carriers"
import { TextArrayField , RefferenceArraySelect  } from "../components/chip"



export const ManageUserButton = ({ record }) => (
    <EditButton onClick={ () => { window.location = `/#/users/` + record.id}} label="edit" record={record} />
);

export const UserList = (props) =>  <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
        <TextArrayField source="carriers">
            <SingleFieldList>
                <ChipField source="id" />
            </SingleFieldList>
        </TextArrayField>
        <TextArrayField source="states">
            <SingleFieldList>
                <ChipField source="id" />
            </SingleFieldList>
        </TextArrayField>
            <ManageUserButton />

        </Datagrid>
    </List>
;

export const CreateUser = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <SelectArrayInput label="Carriers" choices={Object.keys(carriers).map(k => {
                return { id: k, name: carriers[k] }
            })}  />
            <SelectArrayInput label="States" choices={Object.keys(states).map(k => {
                return { id: k, name: states[k] }
            })} />
        </SimpleForm>
    </Create>
);

const Test = (props) => {
    const {
        choices, // An array of records matching both the current input value and the filters
        error, // A potential error that may have occured while fetching the data
        warning, // A potential warning regarding missing references 
        loaded, // boolean that is false until the data is available
        loading, // boolean that is true on mount, and false once the data was fetched
        setFilter, // a callback to update the filters, e.g. setFilters({ q: 'query' })
        setPagination, // a callback to change the pagination, e.g. setPagination({ page: 2, perPage: 50 })
        setSort, // a callback to change the sort, e.g. setSort({ field: 'name', order: 'DESC' })
        setSortForList, // a callback to set the sort with the same signature as the one from the ListContext. This is required to avoid breaking backward compatibility and will be removed in v4
    } = useReferenceArrayInputContext();
    console.log("props",props)

    setFilter({state: "NY"})
    return <SelectArrayInput  { ...props} choices={ choices.filter( f => Object.keys(states).includes(f.state))} optionText="name" />
}

export const EditUser = (props) => { 

    console.log("props", props)
    return <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <SelectArrayInput label="Carriers" source="carriers" choices={Object.keys(carriers).map(k => {
                return { id: k, name: carriers[k] }
            })} />
            <SelectArrayInput label="States" source="states" choices={Object.keys(states).map(k => {
                return { id: k, name: states[k] }
            })} />
            <ReferenceArrayInput filter={{ state: Object.keys(states)}} source="marketplace_carriers" reference="marketplace-carriers" >
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            
        </SimpleForm>
    </Edit>
};